/*
 * File: AddPatientInsurance.tsx
 * Project: mint-portal
 * File Created: Thursday, 30th March 2023 3:10:37 pm
 * Author: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Last Modified: Monday, 1st April 2024 7:35:20 pm
 * Modified By: Jackson Thounaojam (jackson.thounaojam@mutualmobile.com)
 * -----
 * Copyright 2020 - 2023 Mutual Mobile, Mutual Mobile
 */
import { Box, Button, Typography } from '@material-ui/core';
import { FormControl, FormControlLabel, Paper, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  IFamilyMemberDetailsRO,
  InsuranceListAPIResponse,
  InsurancesResponse,
  IPatientDetails,
} from '../../../../model';
import { InsuranceService } from '../../../../service/insurance.service';
import { zeplinColor } from '../../../../theme';
import { Constants } from '../../../../utilities/constants';
import { handleKeyPressForEscapeSymbols } from '../../../../utilities/validate';
import BreadCrumbs from '../../../shared/BreadCrumbs/BreadCrumbs';
import Buttons from '../../../shared/Buttons';
import CustomNumericInput from '../../../shared/CustomNumericField';
import PhoneFieldInput from '../../../shared/CustomPhoneField/CustomPhoneField';
import Field from '../../../shared/CustomTextField';
import Loader from '../../../shared/Loader/Loader';
import SearchDropdown from '../../../shared/SearchDropdown/SearchDropdown';
import _debounce from 'lodash/debounce';
import SnackBar from '../../../shared/Snack-Bar/snackBar';
import CustomDatePicker from '../../../shared/CustomDatePicker';
import moment from 'moment';
import { PatientService } from '../../../../service/patient.service';

const AddPatientInsurance = () => {
  const route = useLocation();
  const navigate = useNavigate();
  const patientId = route.pathname.split('/')[2];
  const familyId = route.pathname.includes('family-member') ? route.pathname.split('/')[4] : '';
  const patientFirstName = route.pathname.includes('family-member')
    ? decodeURI(route.pathname.split('/')[6])
    : decodeURI(route.pathname.split('/')[4]);
  const patientLastName = route.pathname.includes('family-member')
    ? decodeURI(route.pathname.split('/')[7])
    : decodeURI(route.pathname.split('/')[5]);
  const prevLink = [
    { path: '/patient', label: 'Patients' },
    {
      path: familyId ? `/patient/${patientId}/family-member/${familyId}` : `/patient/${patientId}`,
      label: `${patientFirstName + ' ' + patientLastName}`,
    },
  ];
  const [inputData, setInputData] = useState({
    isUsingPrimaryInsurance: false,
    insurance: {
      id: '',
      insuranceName: '',
    },
    groupNumber: 0,
    insurancePhoneNumber: '',
    memberIdNumber: '',
    employerName: '',
    homeZipCode: '',
    isInsurancePolicyHolder: false,
    policyHolderFirstName: '',
    policyHolderLastName: '',
    dateOfBirth: '',
  });

  const [showLoader, setShowLoader] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isForbiddenAccess, setForbiddenAccess] = useState(false);

  const [insuranceList, setInsuranceList] = useState<InsurancesResponse>({
    insurances: [],
    total: 0,
  });
  const [selectInsurance, setSelectInsurance] = useState({
    id: 0,
    insuranceName: '',
  });
  const [date, setDate] = useState<any>(
    moment(inputData?.dateOfBirth.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
  );

  const addInsurance = async () => {
    setShowLoader(true);
    try {
      const { insurance, isUsingPrimaryInsurance, groupNumber, ...restData } = inputData;
      console.log(insurance, 'insurance');
      if (familyId) {
        await InsuranceService.addFamilyInsurance(
          {
            ...restData,
            insuranceId: selectInsurance.id,
            isUsingPrimaryInsurance,
            groupNumber: groupNumber.toString(),
          },
          familyId,
        );
        navigate(`/patient/${patientId}/family-member/${familyId}`);
      } else {
        await InsuranceService.addPatientInsurance(
          { ...restData, insuranceId: selectInsurance.id, groupNumber: groupNumber.toString() },
          patientId,
        );
        navigate(`/patient/${patientId}`);
      }
      sessionStorage.setItem(
        'patient-insurance',
        `Insurance details ${route.pathname.includes('add') ? 'added' : 'updated'}.`,
      );
    } catch (err) {
      setShowLoader(false);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowSnackbar(true);
    }
  };

  const getPatientInsurance = async (patientId: string) => {
    try {
      const res = await InsuranceService.getInsuranceByPatient(patientId);
      if (res.data) {
        const data = res.data;
        setInputData({
          ...inputData,
          ...data,
          groupNumber: parseInt(data.groupNumber),
          insurance: {
            id: data.insurance.id ? data.insurance.id.toString() : '',
            insuranceName: data.insurance.insuranceName,
          },
        });
        setSelectInsurance(res.data.insurance);
        setDate(
          moment(res.data?.dateOfBirth.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
        );
      }
    } catch (err) {
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowSnackbar(true);
    }
  };
  const getFamilyInsurance = async (familyId: string) => {
    try {
      const res = await InsuranceService.getInsuranceByFamily(familyId);
      if (res.data) {
        const data = res.data;
        setInputData({
          ...inputData,
          ...data,
          groupNumber: parseInt(res.data.groupNumber),
          insurance: {
            id: data.insurance.id ? data.insurance.id.toString() : '',
            insuranceName: data.insurance.insuranceName,
          },
        });
        setDate(
          moment(res.data?.dateOfBirth.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
        );
        setSelectInsurance(res.data.insurance);
      }
    } catch (err) {
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      setShowSnackbar(true);
    }
  };
  console.log({
    isForbiddenAccess,
  });
  const getDetails = async (id: string) => {
    try {
      const res: IPatientDetails = await PatientService.getPatient(id);

      setInputData({
        ...inputData,
        policyHolderFirstName: res?.data.firstName ? res?.data.firstName : '',
        policyHolderLastName: res?.data.lastName ? res?.data.lastName : '',
        dateOfBirth: res?.data?.dateOfBirth ? res?.data?.dateOfBirth : '',
      });
      setDate(moment(res.data?.dateOfBirth.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD));
    } catch (err) {
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
    }
  };

  const getFamilyDetails = async (primary: string, family: string) => {
    try {
      const res: IFamilyMemberDetailsRO = await PatientService.getFamilyMemberDetails(
        primary,
        family,
      );

      setInputData({
        ...inputData,
        policyHolderFirstName: res?.data.firstName ? res?.data.firstName : '',
        policyHolderLastName: res?.data.lastName ? res?.data.lastName : '',
        dateOfBirth: res?.data?.dateOfBirth ? res?.data?.dateOfBirth : '',
      });
      setDate(moment(res.data?.dateOfBirth.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD));
    } catch (err) {
      setShowSnackbar(true);
      if ((err as any)?.response?.status === Constants.API_STATUS_CODE.FORBIDDEN) {
        setForbiddenAccess(true);
      }
      setSnackbarMessage(
        typeof err === 'string' ? err : (err as any).response.data.error.message[0],
      );
    }
  };

  const handleInputChange = (event: any, phone = false) => {
    if (phone) {
      const value = event?.split('+');
      if ((value[1] && value[1]?.length <= 11) || !value[1]) {
        setInputData({ ...inputData, insurancePhoneNumber: event.toString() });
      }
    } else {
      setInputData({
        ...inputData,
        [event.target.name]:
          event.target.value === 'true'
            ? true
            : event.target.value === 'false'
              ? false
              : event.target.value,
      });
    }
  };

  const getInsuranceList = async (searchString?: string) => {
    try {
      const limit = Constants.PAGINATION_OPTIONS.limit;
      const insuranceListQueryParams =
        searchString && searchString?.length > 2
          ? { searchString }
          : {
            limit: limit,
            skip: 0,
          };
      const response: InsuranceListAPIResponse = await InsuranceService.getInsuranceList(
        insuranceListQueryParams,
      );
      setInsuranceList(response?.data);
    } catch (error) {
      setShowSnackbar(true);
      setSnackbarMessage(
        typeof error === 'string' ? error : (error as any).response.data.error.message[0],
      );
    }
  };
  const onSearchInsurance = async (searchVal: string) => {
    if (searchVal?.length > 2) {
      _debounce(getInsuranceList(searchVal) as any, 2000);
    }
  };
  const onSelectInsurance = (inputValue: any) => {
    setSelectInsurance(inputValue);
    setInputData({
      ...inputData,
      insurance: { id: inputValue?.id, insuranceName: inputValue?.insuranceName },
    });
  };

  useEffect(() => {
    if (route.pathname.includes('edit')) {
      if (familyId) {
        getFamilyInsurance(familyId);
      } else if (patientId) {
        getPatientInsurance(patientId);
      }
    }

    getInsuranceList();
  }, [patientId, familyId]);

  useEffect(() => {
    if (inputData?.isUsingPrimaryInsurance) {
      getPatientInsurance(patientId);
    } else {
      setInputData({
        ...inputData,
        isInsurancePolicyHolder: false,
        groupNumber: 0,
        insurancePhoneNumber: '',
        memberIdNumber: '',
        employerName: '',
        homeZipCode: '',
        policyHolderFirstName: '',
        policyHolderLastName: '',
        dateOfBirth: '',
      });
      setSelectInsurance({ id: 0, insuranceName: '' });
      setDate(null);
    }
  }, [inputData?.isUsingPrimaryInsurance]);

  useEffect(() => {
    if (inputData?.isInsurancePolicyHolder) {
      if (inputData?.isUsingPrimaryInsurance || !familyId) {
        getDetails(patientId);
      } else {
        getFamilyDetails(patientId, familyId);
      }
    } else {
      setInputData({
        ...inputData,
        policyHolderFirstName: '',
        policyHolderLastName: '',
        dateOfBirth: '',
      });
      setDate(null);
    }
  }, [inputData?.isInsurancePolicyHolder]);
  const handleDate = (newValue: string | Date | null) => {
    setDate(moment(newValue?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD));
    setInputData({
      ...inputData,
      dateOfBirth: moment(newValue?.toString()).format(Constants.MOMENT_FORMATS.YYYY_MM_DD),
    });
  };

  return (
    <>
      {showLoader ? (
        <Loader />
      ) : (
        <div style={{ margin: '32px 0px 32px 24px' }}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant='h5'>
                {route.pathname.includes('edit') ? 'Edit Insurance' : 'Add Insurance'}
              </Typography>
              {prevLink ? (
                <BreadCrumbs
                  prevLink={prevLink}
                  activeLink={route.pathname.includes('edit') ? 'Edit Insurance' : 'Add Insurance'}
                />
              ) : (
                ''
              )}
            </Box>
            <div style={{ display: 'flex', gap: '16px' }}>
              <Button
                variant='outlined'
                style={{
                  width: '8em',
                  height: '34px',
                  backgroundColor: `${zeplinColor.Background97}`,
                  border: `1px solid ${zeplinColor.Background70}`,
                  color: `${zeplinColor.Background70}`,
                }}
                onClick={() =>
                  navigate(
                    familyId
                      ? `/patient/${patientId}/family-member/${familyId}`
                      : `/patient/${patientId}`,
                  )
                }
              >
                Cancel
              </Button>
              <Buttons
                text={route.pathname.includes('edit') ? 'SAVE CHANGES' : 'ADD INSURANCE'}
                width='160px'
                disable={
                  Object.values(inputData).some((val: any) => typeof val === 'string' && !val) ||
                  inputData.insurancePhoneNumber.length !== 12 ||
                  (inputData?.homeZipCode?.length !== 5 && inputData?.homeZipCode?.length !== 9) ||
                  !inputData?.insurance?.id ||
                  !inputData.dateOfBirth
                    ? true
                    : false
                }
                onClick={addInsurance}
                bgWhite={false}
              />
            </div>
          </Box>
          <Paper
            elevation={0}
            className=''
            sx={{
              borderRadius: '16px',
              padding: '40px 0px 3rem 35px',
              marginTop: '35px',
              border: `1px solid ${zeplinColor.Background90}`,
            }}
          >
            <section
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '1.5rem',
                width: '50%',
              }}
            >
              {route.pathname.includes('family') ? (
                <section>
                  {' '}
                  <FormControl>
                    <Typography variant='subtitle1' style={{ paddingLeft: 10 }}>
                      Use the same insurance as the primary patient?*
                    </Typography>
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='isUsingPrimaryInsurance'
                      value={
                        inputData?.insurance?.id || route.pathname.includes('edit')
                          ? inputData.isUsingPrimaryInsurance
                          : undefined
                      }
                      onChange={(e) => handleInputChange(e)}
                      style={{ paddingLeft: 10 }}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: `${zeplinColor.Primary}`,
                              },
                            }}
                          />
                        }
                        label={<Typography variant='body1'>Yes</Typography>}
                      />
                      <FormControlLabel
                        value={false}
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: `${zeplinColor.Primary}`,
                              },
                            }}
                          />
                        }
                        label={<Typography variant='body1'>No</Typography>}
                      />
                    </RadioGroup>
                  </FormControl>
                  <FormControlLabel
                    value={undefined}
                    control={
                      <Radio
                        sx={{
                          '&.Mui-checked': {
                            color: `${zeplinColor.Primary}`,
                          },
                          display: 'none',
                        }}
                      />
                    }
                    label={<Typography variant='body1'></Typography>}
                    defaultChecked
                  />
                </section>
              ) : (
                ''
              )}
              <section style={{ marginLeft: '0.6rem' }}>
                {/* <Field
                  name='insuranceCarrierName'
                  label='Insurance Carrier Name'
                  placeholder='Clinic Name'
                  showIcon={false}
                  style={{ width: '100%' }}
                  value={inputData.insuranceCarrierName}
                  onChange={handleInputChange}
                  disable={false}
                /> */}

                <SearchDropdown
                  data-testid='dropdown'
                  searchList={insuranceList.insurances}
                  width={'100%'}
                  label={'Insurance Carrier Name*'}
                  onSelectHandler={onSelectInsurance}
                  onSearchHandler={onSearchInsurance}
                  multiple={false}
                  value={selectInsurance}
                  insurances={true}
                  patientInsurance={true}
                  disable={inputData?.isUsingPrimaryInsurance}
                  // fixed={selectOperatory ? true : false}
                />
              </section>
              <section>
                <CustomNumericInput
                  name='groupNumber'
                  width={'100%'}
                  label='Group Number*'
                  placeholder='Group Number'
                  value={inputData.groupNumber}
                  error={false}
                  disabled={inputData?.isUsingPrimaryInsurance ? true : false}
                  onKeyPress={handleKeyPressForEscapeSymbols}
                  onChange={(e) => handleInputChange(e)}
                  icon={false}
                  marginTop='-0.7rem'
                  dynamicValue
                />
              </section>
              {/* <section>
                <Field
                  name='groupNumber'
                  width={'100%'}
                  label='Group Number'
                  placeholder='Group Number'
                  value={inputData.groupNumber}
                  error={false}
                  disable={false}
                  onChange={(e) => handleInputChange(e)}
                />
              </section> */}
              <section style={{ position: 'relative' }}>
                <PhoneFieldInput
                  name='insurancePhoneNumber'
                  value={inputData.insurancePhoneNumber}
                  width='100%'
                  onChange={(e) => handleInputChange(e, true)}
                  placeholder='Insurance Phone Number*'
                  padding={false}
                  margin={true}
                  error={false}
                  disable={inputData?.isUsingPrimaryInsurance}
                />
              </section>
              <section>
                <Field
                  name='memberIdNumber'
                  label='Member Id Number*'
                  placeholder='Member Id Number'
                  showIcon={false}
                  style={{ width: '100%' }}
                  value={inputData.memberIdNumber}
                  onChange={handleInputChange}
                  disable={inputData?.isUsingPrimaryInsurance}
                />
              </section>
              <section>
                <Field
                  name='employerName'
                  label='Employer Name*'
                  placeholder='Employer Name'
                  showIcon={false}
                  style={{ width: '100%' }}
                  value={inputData.employerName}
                  onChange={handleInputChange}
                  disable={inputData?.isUsingPrimaryInsurance}
                />
              </section>
              <section>
                <Field
                  name='homeZipCode'
                  label='Home Zipcode*'
                  placeholder='Home Zipcode'
                  showIcon={false}
                  style={{ width: '100%' }}
                  value={inputData.homeZipCode}
                  error={
                    inputData.homeZipCode &&
                    inputData.homeZipCode.length != 5 &&
                    inputData.homeZipCode.length != 9
                      ? true
                      : false
                  }
                  onChange={handleInputChange}
                  disable={inputData?.isUsingPrimaryInsurance}
                />
              </section>
              {/* <section>
                <CustomNumericInput
                  name='homeZipCode'
                  width={'100%'}
                  label='Home Zipcode'
                  placeholder='Home Zipcode'
                  value={parseInt(inputData.homeZipCode)}
                  error={false}
                  disabled={false}
                  onKeyPress={handleKeyPressForEscapeSymbols}
                  onChange={(e) => handleInputChange(e)}
                  icon={false}
                  marginTop='-0.7rem'
                />
              </section> */}
              <section>
                {' '}
                {inputData?.isUsingPrimaryInsurance ? (
                  ''
                ) : (
                  <FormControl>
                    <Typography variant='subtitle1' style={{ paddingLeft: 10 }}>
                      Is the patient the insurance holder?*
                    </Typography>
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='isInsurancePolicyHolder'
                      value={
                        inputData?.insurance?.id || route.pathname.includes('edit')
                          ? inputData.isInsurancePolicyHolder
                          : undefined
                      }
                      onChange={(e) => handleInputChange(e)}
                      style={{ paddingLeft: 10 }}
                      row
                    >
                      <FormControlLabel
                        value={true}
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: `${zeplinColor.Primary}`,
                              },
                            }}
                          />
                        }
                        label={<Typography variant='body1'>Yes</Typography>}
                      />
                      <FormControlLabel
                        value={false}
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: `${zeplinColor.Primary}`,
                              },
                            }}
                          />
                        }
                        label={<Typography variant='body1'>No</Typography>}
                      />
                      <FormControlLabel
                        value={undefined}
                        control={
                          <Radio
                            sx={{
                              '&.Mui-checked': {
                                color: `${zeplinColor.Primary}`,
                              },
                              display: 'none',
                            }}
                          />
                        }
                        label={<Typography variant='body1'></Typography>}
                        defaultChecked
                      />
                    </RadioGroup>
                  </FormControl>
                )}
              </section>
              <section>
                <Field
                  name='policyHolderFirstName'
                  label='Policy Holder’s First Name*'
                  placeholder='Policy Holder’s First Name'
                  showIcon={false}
                  style={{ width: '100%' }}
                  value={inputData.policyHolderFirstName}
                  onChange={handleInputChange}
                  disable={inputData?.isInsurancePolicyHolder ? true : false}
                />
              </section>
              <section>
                <Field
                  name='policyHolderLastName'
                  label='Policy Holder’s Last Name*'
                  placeholder='Policy Holder’s Last Name'
                  showIcon={false}
                  style={{ width: '100%' }}
                  value={inputData.policyHolderLastName}
                  onChange={handleInputChange}
                  disable={inputData?.isInsurancePolicyHolder ? true : false}
                />
              </section>
              {/* <section>
                <Field
                  name='dateOfBirth'
                  label='Policy Holder’s DOB'
                  placeholder='Policy Holder’s DOB'
                  showIcon={false}
                  style={{ width: '100%' }}
                  value={inputData.dateOfBirth}
                  onChange={handleInputChange}
                  disable={inputData?.isInsurancePolicyHolder ? true : false}
                />
              </section> */}
              <section style={{ paddingLeft: '0.5rem' }}>
                <CustomDatePicker
                  value={date}
                  handleDateChange={handleDate}
                  disablePast={false}
                  endDate={moment(new Date().toString()).format('YYYY-MM-DD')}
                  width={'100%'}
                  label='Policy Holder’s DOB*'
                  disabled={
                    inputData?.isInsurancePolicyHolder || inputData?.isUsingPrimaryInsurance
                  }
                />
              </section>
            </section>
          </Paper>
        </div>
      )}
      {showSnackbar && (
        <SnackBar message={snackbarMessage} show={showSnackbar} setShow={setShowSnackbar} />
      )}
    </>
  );
};

export default AddPatientInsurance;
