/*
 * File: constants.ts
 * Project: mint-portal
 * File Created: Thursday, 30th June 2022 2:59:54 pm
 * Author: Priya Gupta (priya.gupta@mutualmobile.com)
 * -----
 * Last Modified: Monday, 25th September 2023 7:02:59 am
 * Modified By: Sowmiya Ramesh (sowmiya.ramesh@mutualmobile.com)
 * -----
 * Copyright 2020 - 2022 Mutual Mobile, Mutual Mobile
 */

import { AppModules, RoleIds } from './roles';

export interface IRoleDescription {
  [key: number]: string[];
}
export class Constants {
  public static readonly PAGINATION_OPTIONS = {
    limit: 10,
    skip: 0,
  };

  public static readonly RewardCount = [100, 200, 500, 1000];

  public static SNACKBAR_DURATION = 5000;

  public static readonly hourOptions = [
    { id: 1, name: '1' },
    { id: 2, name: '2' },
  ];

  public static readonly clinicOptions = [
    { id: 'all clinics', name: 'All Clinics' },
    { id: 'select clinics', name: 'Select Clinics' },
  ];
  public static readonly rangeOptions = [
    { id: 'today', name: 'Today' },
    { id: 'yesterday', name: 'Yesterday' },
    { id: 'last week', name: 'Last Week' },
    { id: 'last month', name: 'Last Month' },
    { id: 'last 3 months', name: 'Last 3 Months' },
    { id: 'custom', name: 'Custom Date Range' },
  ];

  public static readonly APPOINTMENT_STATUSES = [
    { id: 1, name: 'Scheduled' },
    { id: 2, name: 'Complete' },
    { id: 3, name: 'Unscheduled' },
    { id: 4, name: 'ASAP' },
    { id: 5, name: 'Broken' },
    { id: 6, name: 'Planned' },
    { id: 7, name: 'PT Note' },
    { id: 8, name: 'PT Note Completed' },
  ];

  public static readonly HEADERS_CONTENT_TYPE = {
    imageType: 'image/jpeg',
    pdfType: 'application/pdf',
  };

  public static readonly IMAGE_TYPE = '.jpg,.png';

  public static readonly LIMIT = 5;

  public static readonly PASSWORD_MIN_LIMIT = 8;

  public static readonly DASHBOARD_LIMIT = 20;

  public static readonly DOUGHNUT_CHART_LIMIT = 10;

  public static readonly SEARCH_LIMIT = 3;

  public static readonly FAQ_LIMIT = 20;

  public static readonly VIDEOS_LIMIT = 50;

  public static readonly OTHER_LINK_LIMIT = 5;

  public static readonly SERVICE_ICON_LIBRARY_LIMIT = 25;

  public static readonly REGEX = {
    emailRegex: /\S+@\S+\.\S+/, // /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/,
    digitsRegex: /^[0-9\b]+$/,
    urlRegex: /(http|https)/,
    name: /^(\s)*[a-zA-Z]+(['’"`]?[a-zA-Z]+)?((-|\s|\s-\s)?[a-zA-Z]+(['’"`]?[a-zA-Z]+)?)?(\s)*$/i,
  };

  public static readonly ESCAPE_SYMBOLS = ['e', 'E', '+', '-', '.'];

  public static readonly FILE_UPLOAD_CATEGORY = {
    STAFF_PROFILE_IMAGE: 1,
    CLINIC_IMAGE: 2,
    SERVICE_IMAGE: 3,
    TERMS_AND_CONDITIONS: 4,
    PRIVACY_POLICY: 5,
    APP_IMAGES: 6,
    REWARD_GIFT_IMAGES: 7,
    REWARD_POLICY: 8,
    ICON_LIBRARY: 9,
    LINK_ICON_IMAGES: 10,
  };

  public static readonly ROLE_DESCRIPTION: IRoleDescription = {
    [RoleIds.DSO_ADMIN]: [
      'Company level analytics',
      'Access to patient data',
      'Access to rewards feature',
      'App configuration',
      'Create, update and delete clinic information',
    ],
    [RoleIds.MARKETING_ADMIN]: ['Access to rewards feature', 'App configuration'],
    [RoleIds.CLINIC_ADMIN]: [
      'Clinic level analytics',
      'Update clinic information',
      'Access to patient data',
      'Access to rewards feature',
    ],
    [RoleIds.FRONT_OFFICE_MANAGER]: [
      'Update clinic information',
      'Access to patient data for assigned clinic',
    ],
  };

  public static readonly PERMISSIONS: { [char: string]: AppModules } = {
    [RoleIds.DSO_ADMIN]: {
      Dashboard: { create: true, update: true, delete: true, read: true },
      Staff: { create: true, update: true, delete: true, read: true },
      Service: { create: true, update: true, delete: true, read: true },
      Clinics: {
        create: true,
        update: true,
        delete: true,
        read: true,
        readApikey: true,
        regenerateAPiKey: true,
      },
      Patient: { create: false, update: true, delete: true, read: true },
      Rewards: { create: true, update: true, delete: true, read: true },
      AutoAssignPoints: { create: true, update: true, delete: false, read: true },
      Insurances: { create: true, update: true, delete: true, read: true },
      Manage: { create: true, update: true, delete: true, read: true },
      Booking: { create: true, update: true, delete: true, read: true },
    },
    [RoleIds.MARKETING_ADMIN]: {
      Dashboard: { create: true, update: true, delete: true, read: true },
      Staff: { create: false, update: false, delete: false, read: false },
      Service: { create: false, update: false, delete: false, read: true },
      Clinics: {
        create: false,
        update: false,
        delete: false,
        read: true,
        readApikey: false,
        regenerateAPiKey: false,
      },
      Patient: { create: false, update: false, delete: false, read: false },
      Rewards: { create: true, update: true, delete: true, read: true },
      AutoAssignPoints: { create: true, update: true, delete: false, read: true },
      Insurances: { create: false, update: false, delete: false, read: true },
      Manage: { create: true, update: true, delete: true, read: true },
      Booking: { create: false, update: false, delete: false, read: true },
    },
    [RoleIds.CLINIC_ADMIN]: {
      Dashboard: { create: true, update: true, delete: true, read: true },
      Staff: { create: false, update: true, delete: false, read: true },
      Service: { create: false, update: false, delete: false, read: true },
      Clinics: {
        create: false,
        update: true,
        delete: false,
        read: true,
        readApikey: false,
        regenerateAPiKey: false,
      },
      Patient: { create: false, update: true, delete: false, read: true },
      Rewards: { create: true, update: true, delete: false, read: true },
      AutoAssignPoints: { create: false, update: false, delete: false, read: true },
      Insurances: { create: true, update: true, delete: false, read: true },
      Manage: { create: false, update: false, delete: false, read: true },
      Booking: { create: false, update: true, delete: false, read: true },
    },
    [RoleIds.FRONT_OFFICE_MANAGER]: {
      Dashboard: { create: true, update: true, delete: true, read: true },
      Staff: { create: false, update: false, delete: false, read: true },
      Service: { create: false, update: false, delete: false, read: true },
      Clinics: {
        create: false,
        update: true,
        delete: false,
        read: true,
        readApikey: false,
        regenerateAPiKey: false,
      },
      Patient: { create: false, update: true, delete: false, read: true },
      Rewards: { create: false, update: false, delete: false, read: true },
      AutoAssignPoints: { create: false, update: false, delete: false, read: true },
      Insurances: { create: true, update: true, delete: false, read: true },
      Manage: { create: false, update: false, delete: false, read: false },
      Booking: { create: false, update: true, delete: false, read: true },
    },
  };

  public static readonly SERVICE = {
    AGE_TYPE: {
      adult: 'Adult',
      child: 'Child',
    },
    PATIENT_TYPE: {
      existing: 'Existing',
      new: 'New',
    },
    DURATION_UNIT: 'Min',
  };

  public static readonly REWARD_TYPES = {
    Gift: 'Gift',
    PointsCredit: 'Points Credit',
    ManualCredit: 'Manual Credit',
    ReferTo: 'Refer To',
    ReferBy: 'Refer By',
    AutoAssignedPoints: 'Auto-assigned Points',
  };

  public static readonly MOMENT_FORMATS = {
    DATE: 'MMM DD, YYYY',
    TIME: 'hh:mm A',
    TIME_LOWERCASE: 'hh:mm a',
    DATE_TIME: 'LLL',
    YYYY_MM_DD: 'YYYY-MM-DD',
    HH_MM_SS: 'HH:mm:ss',
    DATE_FORMAT: 'D MMM, YYYY',
    HH_MM: 'HH:mm',
  };

  public static API_STATUS_CODE = {
    ERR_NETWORK: 'ERR_NETWORK',
    FORBIDDEN: 403,
  };

  public static FALL_BACK_IMAGE = '/user-icon 1.svg';

  public static SERVICE_FALL_BACK_ICON = '/mint-service-icon.svg';

  public static readonly REWARDS_VALIDATION = {
    MAX_POINT_VALUE: 1000000,
    MAX_INVENTORY_COUNT: 100000,
  };

  public static readonly NO_SEARCH_RESULTS = 'No search results found';

  public static readonly ERROR_MESSAGES = {
    FILE: {
      MAX_NUMBER_EXCEED: 'Maximum limit reached.You can add a new image by removing one below.',
      MAX_1MB_SIZE_EXCEED:
        'Image exceeds maximum size of 1 MB. Please try uploading a different one.',
      MAX_NUMBER_FILES_5: 'You can upload upto 5 images only. Please try again.',
      MAX_NUMBER_FILES_3: 'You can upload upto 3 images only. Please try again.',
      MAX_1MB_ALL_IMAGES: 'Please ensure all images uploaded are under 1 MB and try again.',
    },
    REWARDS: {
      MAX_POINT_VALUE_EXCEED: 'Points Value cannot be more than 1000000.',
      MAX_INVENTORY_COUNT_EXCEED: 'Inventory count cannot be more than 100000.',
    },
    TEXT_VALIDATION: {
      ALLOW_ALPHABET_MESSAGE:
        'Special characters and numbers cannot be used in the title. Please try again.',
    },
    UPDATE_PATIENT: {
      PHONE_NUMBER_EXISTS: 'Phone number already in use for another account, please review',
      EMAIL_EXISTS: 'Email already in use for another account, please review',
    }
  };

  public static readonly INFO_TEXTS = {
    CREATE_CLINIC: 'Complete all the required fields marked with * in order to create a clinic.',
    GOOGLE_BUSINESS_PROFILE: 'Your Google Business profile is authenticated.',
    GOOGLE_BUSINESS_AUTH:
      'Please authenticate your Google Business profile to update location details.',
    WARNING_SAVE_CLINIC:
      'Use the ‘Save Details’ button at the bottom of this section to save your changes. Any unsaved changes will be lost.',
    NEW_SERVICE_IN_CLINIC: 'To create new service, go to Services > Add New Service.',
    ASSIGN_OPERATORY: 'Please ensure to assign an operatory after adding a new service.',
    NEW_STAFF_IN_CLINIC: 'To create new staff, go to Staff > Create staff',
    NEW_INSURANCE_IN_CLINIC: 'To create new insurance, go to Insurances > Create insurance',
    LANDING_CAROUSEL_DRAG_AND_DROP:
      'You can change the order of the images by dragging and dropping them',
    LEGAL_DOCUMENT_WARNING:
      'Upon reuploading the document, the old version will be replaced with the new one',
    INSURANCE_INFO_TEXT:
      'Insurances are assigned to all clinics by default. Changes can be made for any clinic on their respective clinic page.\n Go to, Clinics > Clinic Page > Insurances',
    FAQ_INFO_TEXT:
      'You can add up to 20 FAQs. Modify the order of the rows below by dragging and dropping them.',
    APPOINTMENT_NOTIFICATION_INFO_TEXT:
      'You can schedule only up to 10 notifications for appointments',
    SERVICE_ICON_LIBRARY: 'Upload transparent PNG images sized 128 x 128 for optimal results',
    SERVICE_ICON_LIBRARY_EMPTYSTATE:
      'Upload transparent PNG images sized 128 x 128 for optimal results. You can upload up to 25 icons.',
    VIDEOS_INFO_TEXT:
      'You can add up to 50 videos. Modify the order of the rows below by dragging and dropping them.',
    OTHER_LINK_INFO_TEXT:
      'You can add up to 5 links. Modify the order of the rows below by dragging and dropping them.',
    ADD_VIDEO_INFO_TEXT: 'Use only Youtube links for the video URL',
    PATIENT_PROFILE_EDIT_INFO_TEXT:
      'Please ensure to have consent from the Patient to before editing the details below',
    EDIT_PATIENT_EXISTING_ALERT:
      'The details entered already exists for another user, please review.',
  };
  public static readonly PATIENTS = {
    GENDER: {
      male: 'Male',
      female: 'Female',
      other: 'Others',
      // eslint-disable-next-line camelcase
    },
  };
  public static readonly MAIL_TO = {
    ID: 'mintapp@mintdentistry.com',
  };
}
